<script>
export default {
  name: "left-bar-head",
  data(){
    return {
      search: null
    }
  },
  methods: {
    searchConversation(){
      this.$emit("searchConversation", this.search)
    }
  },
  watch: {
    search: function (val) {
      this.searchConversation(val)
    }
  }
}
</script>

<template>
  <div class="px-4 pt-4 mb-4">
    <div class="d-flex align-items-start">
      <div class="flex-grow-1">
        <h5 class="mb-4">Chats</h5>
      </div>
      <div class="flex-shrink-0">
        <div data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" title="Add Contact">
          <!-- Button trigger modal -->
          <button type="button" class="btn btn-soft-success btn-sm">
            <i class="ri-add-line align-bottom"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="search-box">
      <input type="text" class="form-control bg-light border-light" placeholder="Search here..." v-model.trim="search" />
      <i class="ri-search-2-line search-icon"></i>
    </div>
  </div>
</template>

<style scoped>

</style>