<script>
export default {
  name: "chat-message",
  props: {
    chatMessagesData: {
      type: Array,
      default: () => []
    },
    profile: {
      required: true
    }
  }
}
</script>

<template>
  <div class="chat-conversation p-3 p-lg-4" id="chat-conversation" data-simplebar ref="current">
    <ul class="list-unstyled chat-conversation-list">
      <li v-for="data of chatMessagesData" :key="data.message" :class="{
                            right: `${data.align}` === 'right',
                            left: `${data.align}` !== 'right',
                          }" class="chat-list">
        <div class="conversation-list">
          <div class="chat-avatar" v-if="data.align !== 'right'">
            <img :src="profile ? profile : require('@/assets/images/users/user-dummy-img.jpg')" alt="" />
          </div>
          <div class="user-chat-content">
            <div class="ctext-wrap">
              <div class="ctext-wrap-content">
                <p class="mb-0 ctext-content">
                  {{ data.message }}
                </p>
              </div>
              <div class="dropdown align-self-start message-box-drop">
                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </a>
                <div class="dropdown-menu">
                  <a class="dropdown-item reply-message" href="#"><i
                      class="ri-reply-line me-2 text-muted align-bottom"></i>Reply</a>
                  <a class="dropdown-item" href="#"><i
                      class="ri-share-line me-2 text-muted align-bottom"></i>Forward</a>
                  <a class="dropdown-item copy-message" href="#"><i
                      class="ri-file-copy-line me-2 text-muted align-bottom"></i>Copy</a>
                  <a class="dropdown-item" href="#"><i
                      class="ri-bookmark-line me-2 text-muted align-bottom"></i>Bookmark</a>
                  <a class="dropdown-item delete-item" href="#"><i
                      class="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>Delete</a>
                </div>
              </div>
              <div class="conversation-name">
                <small class="text-muted time">{{
                    data.time
                  }}</small>
                <span class="text-success check-message-icon"><i
                    class="ri-check-double-line align-bottom"></i></span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <!-- end chat-conversation-list -->
  </div>
</template>

<style scoped>

</style>