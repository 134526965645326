<script>
import {
  required,
  helpers
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import Layout from "@/layouts/main.vue";

import {
  chatData,
  chatMessagesData
} from "./data";
import LeftBarHead from "../../../components/chat/left-bar-head.vue";
import ConversationList from "../../../components/chat/conversation-list.vue";
import ConversationUniqueHeader from "../../../components/chat/conversation-unique-header.vue";
import ChatMessage from "../../../components/chat/chat-message.vue";

export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  page: {
    title: "Chat",

  },
  data() {
    return {
      chatData: chatData,
      chatMessagesData: chatMessagesData,
      title: "Chat",
      items: [{
        text: "Velzon",
        href: "/",
      },
        {
          text: "Chat",
          active: true,
        },
      ],
      submitted: false,
      form: {
        message: "",
      },
      username: "Steven Franklin",
      profile: require("@/assets/images/users/avatar-2.jpg")
    };
  },
  components: {
    ChatMessage,
    ConversationUniqueHeader,
    ConversationList,
    LeftBarHead,
    Layout
  },
  validations: {
    form: {
      message: {
        required: helpers.withMessage("Message is required", required),
      },
    },
  },
  methods: {
    searchConversation(query) {
      console.log(query)
      this.chatData = chatData.filter((item) => {
        return item.name.toLowerCase().includes(query.toLowerCase());
      });

    },
    /**
     * Get the name of user
     */
    scrollToBottom(id) {
      setTimeout(function () {
        var simpleBar = document.getElementById(id).querySelector(
            "#chat-conversation .simplebar-content-wrapper") ?
            document.getElementById(id).querySelector(
                "#chat-conversation .simplebar-content-wrapper") : ''

        var offsetHeight = document.getElementsByClassName("chat-conversation-list")[0] ?
            document.getElementById(id).getElementsByClassName("chat-conversation-list")[0]
                .scrollHeight - window.innerHeight + 600 : 0;

        if (offsetHeight)
          simpleBar.scrollTo({
            top: offsetHeight,
            behavior: "smooth"
          });
      }, 300);
    },
    chatUsername(payload = null) {
      if (!payload) return;
      const {
        name,
        profile
      } = payload;
      this.username = name;
      this.profile = profile;
      this.usermessage = "Hello";
      this.chatMessagesData = [];
      const currentDate = new Date();

      for(let i = 0; i < 50; i++) {
        this.chatMessagesData.push({
          align: i % 2 === 0 ? "right" : "left",
          name: this.username,
          message: this.usermessage + " " + i,
          time: currentDate.getHours() + ":" + currentDate.getMinutes(),
        });
      }
    },

    /**
     * Char form Submit
     */
    formSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {
        const message = this.form.message;
        const currentDate = new Date();
        this.chatMessagesData.push({
          align: "right",
          name: "Henry Wells",
          message,
          time: currentDate.getHours() + ":" + currentDate.getMinutes(),
        });
        var currentChatId = "users-chat";
        this.scrollToBottom(currentChatId);
      }
      this.submitted = false;
      this.form = {};
    },
  },
  mounted() {
    var currentChatId = "users-chat";
    this.scrollToBottom(currentChatId);
    document.getElementById('copyClipBoard').style.display = 'none';
    var userChatElement = document.querySelectorAll(".user-chat");
    document.querySelectorAll(".chat-user-list li a").forEach(function (item) {
      item.addEventListener("click", function () {
        userChatElement.forEach(function (elm) {
          elm.classList.add("user-chat-show");
        });

        // chat user list link active
        var chatUserList = document.querySelector(".chat-user-list li.active");
        if (chatUserList) chatUserList.classList.remove("active");
        this.parentNode.classList.add("active");
      });
    });

    // user-chat-remove
    document.querySelectorAll(".user-chat-remove").forEach(function (item) {
      item.addEventListener("click", function () {
        userChatElement.forEach(function (elm) {
          elm.classList.remove("user-chat-show");
        });
      });
    });
  },
};
</script>

<template>
  <Layout>

    <div class="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
      <div class="chat-leftsidebar">
        <left-bar-head @searchConversation="searchConversation"/>
        <!-- .p-4 -->

        <div class="chat-room-list" data-simplebar>
          <div class="d-flex align-items-center px-4 mb-2">
            <div class="flex-grow-1">
              <h4 class="mb-0 fs-11 text-muted text-uppercase">
                Direct Messages
              </h4>
            </div>
            <div class="flex-shrink-0">
              <div data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" title="New Message">
                <!-- Button trigger modal -->
                <button type="button" class="btn btn-soft-success btn-sm">
                  <i class="ri-add-line align-bottom"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="chat-message-list">
            <SimpleBar class="list-unstyled chat-list chat-user-list">
              <conversation-list @changeChat="chatUsername" :chat-data="chatData"/>
            </SimpleBar>
          </div>

          <!-- End chat-message-list -->
        </div>
      </div>
      <!-- end chat leftsidebar -->
      <!-- Start User chat -->
      <div class="user-chat w-100 overflow-hidden">
        <div class="chat-content d-lg-flex">
          <!-- start chat conversation section -->
          <div class="w-100 overflow-hidden position-relative">
            <!-- conversation user -->
            <div class="position-relative">
              <conversation-unique-header :username="username" :profile="profile"/>
              <!-- end chat user head -->

              <div class="position-relative" id="users-chat">
                <chat-message :profile="profile" :chat-messages-data="chatMessagesData"/>
                <div class="alert alert-warning alert-dismissible copyclipboard-alert px-4 fade show" id="copyClipBoard"
                     role="alert">
                  Message copied
                </div>
              </div>

              <!-- end chat-conversation -->

              <div class="chat-input-section p-3 p-lg-4">
                <form @submit.prevent="formSubmit">
                  <div class="row g-0 align-items-center">
                    <div class="col-auto">
                      <div class="chat-input-links me-2">
                        <div class="links-list-item">
                          <button type="button" class="btn btn-link text-decoration-none emoji-btn" id="emoji-btn">
                            <i class="bx bx-smile align-middle"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="chat-input-feedback">
                        Please Enter a Message
                      </div>

                      <input type="text" v-model="form.message" class="form-control chat-input bg-light border-light"
                             placeholder="Enter Message..." :class="{
                              'is-invalid': submitted && v$.form.message.$error,
                            }" />
                      <div v-if="submitted && v$.form.message.$error" class="invalid-feedback">
                        <span v-if="v$.form.message.required.$message">{{
                            v$.form.message.required.$message
                          }}</span>
                      </div>
                    </div>
                    <div class="col-auto">
                      <div class="chat-input-links ms-2">
                        <div class="links-list-item">
                          <button type="submit" class="btn btn-success chat-send waves-effect waves-light">
                            <i class="ri-send-plane-2-fill align-bottom"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="replyCard">
                <div class="card mb-0">
                  <div class="card-body py-3">
                    <div class="replymessage-block mb-0 d-flex align-items-start">
                      <div class="flex-grow-1">
                        <h5 class="conversation-name"></h5>
                        <p class="mb-0"></p>
                      </div>
                      <div class="flex-shrink-0">
                        <button type="button" id="close_toggle" class="btn btn-sm btn-link mt-n2 me-n3 fs-18">
                          <i class="bx bx-x align-middle"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end chat-wrapper -->

  </Layout>
</template>